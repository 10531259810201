.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.link__btn:hover {
  text-decoration: underline;
}

.outline__btn {
  font-family: var(--font-family-primary);
  background-color: var(--color-bg-light);
  cursor: pointer;
  border-radius: 40px;
}

.solid__btn {
  font-family: var(--font-family-primary);
  color: var(--color-bg-light);
  cursor: pointer;
  border-radius: 40px;
}

.link__btn {
  background-color: var(--color-transparent);
  color: var(--pfz-interactive-primary-on-light);
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  margin: 0;
}

.contained__btn {
  font-family: var(--font-family-primary);
  cursor: pointer;
  border-radius: var(--border-radius-lg);
  padding: var(--padding-size-xxs);
  background-color: transparent;
}

.link__btn.btn--disabled {
  opacity: 0.38;
  color: var(--pfz-text-default-color) !important;
  cursor: not-allowed;
}

.size__s {
  height: 2rem;
  align-self: center;
}

.size__lg {
  width: 10.5rem;
  height: 3.5rem;
  font-weight: var(--pfz-font-weight-500);
  font-size: var(--pfz-base-font-size);
}

.size__3xl {
  height: 4.25rem;
  transition:
    background-color 0.3s linear,
    color 0.3s linear;
  height: 4.25rem;
}

.font__3xl {
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-md);
}

.size__m {
  display: flex;
  padding: var(--spacing-02) var(--spacing-06);
  align-items: center;
  height: var(--spacing-10);
}

.size__xs {
  display: flex;
  padding: 8px 24px;
  align-items: center;
  gap: 4px;
  border: 2px solid var(--pfz-interactive-primary-on-light) !important;
  min-width: 104px;
  height: 37px;
}

.solid__btn:focus-visible,
.outline__btn:focus-visible,
.link__btn:focus-visible {
  outline: 1px solid var(--pfz-color-secondary-800);
  outline-offset: 8px;
}

.outline__btn:not(:disabled):hover {
  background-color: var(--pfz-color-primary-100);
}

.outline__btn:not(:disabled):active {
  background-color: var(--pfz-color-primary-300);
}

.outline__btn:not(:disabled):focus {
  background-color: var(--pfz-color-primary-300);
}

.outline__btn:not(:disabled):focus-visible {
  background-color: var(--pfz-color-primary-300);
  box-shadow: 0px 0px 0px 4px rgba(0, 0, 201, 0.4);
}

.outline__btn:disabled {
  border: 2px solid var(--pfz-color-gray-350) !important;
  opacity: 0.38;
  color: var(--pfz-color-gray-350) !important;
  cursor: not-allowed;
}

.ghost__btn {
  color: var(--pfz-text-black);
  font-size: var(--pfz-font-size-3);
  font-weight: var(--font-weight-bold);
  line-height: var(--pfz-line-height-24);
  padding: 4px 12px;
  cursor: pointer;
  border: none;
  background-color: unset;
  text-wrap: nowrap;
  border-radius: var(--border-radius-full);
}

.ghost__btn:not(:disabled):hover {
  background-color: var(--pfz-color-gray-0);
}

.ghost__btn:not(:disabled):active {
  background-color: var(--pfz-color-gray-10);
}

.ghost__btn:not(:disabled):focus {
  background-color: var(--pfz-color-gray-10);
}

.ghost__btn:not(:disabled):focus-visible {
  background-color: var(--pfz-color-gray-10);
  box-shadow: 0px 0px 0px 4px rgba(0, 0, 201, 0.4);
}

.ghost__btn:disabled {
  opacity: 0.38;
  cursor: not-allowed;
}
