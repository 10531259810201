.tag__wrapper {
  display: flex;
  height: var(--spacing-06);
  padding: var(--spacing-01) var(--spacing-02);
  align-items: center;
  gap: var(--spacing-01);
  border-radius: var(--border-radius-rounded-md);
  border-width: var(--border-1);
  border-style: solid;
  -webkit-border-radius: var(--border-radius-rounded-md);
  -moz-border-radius: var(--border-radius-rounded-md);
  -ms-border-radius: var(--border-radius-rounded-md);
  -o-border-radius: var(--border-radius-rounded-md);
}

.tag--default {
  border-color: var(--pfz-border-default-secondary);
  color: var(--pfz-text-default-color);
}

.tag--default svg {
  color: var(--pfz-icon-default);
}

.tag--neutral {
  border-color: var(--pfz-border-default-secondary);
  background-color: var(--pfz-color-gray-0);
  color: var(--pfz-text-default-color);
}

.tag--neutral svg {
  color: var(--pfz-icon-default);
}

.tag--info {
  border-color: var(--pfz-color-primary-300);
  background-color: var(--pfz-color-ghost-white);
  color: var(--pfz-text-default-color);
}

.tag--info svg {
  color: var(--pfz-icon-default);
}

.tag--warning {
  border-color: var(--pfz-border-warning-secondary);
  background-color: var(--pfz-color-orange-200);
  color: var(--pfz-text-warning-default);
}

.tag--warning svg {
  color: var(--pfz-icons-warning);
}

.tag--success {
  border-color: var(--pfz-border-success-tertiary);
  background-color: var(--pfz-color-green-100);
  color: var(--pfz-text-success-default);
}

.tag--success svg {
  color: var(--pfz-icons-success);
}

.tag--error {
  border-color: var(--pfz-border-tag-error);
  background-color: var(--pfz-color-red-100);
  color: var(--pfz-text-error-color);
}

.tag--inprogress {
  border-color: var(--pfz-border-warning-secondary);
  background-color: var(--pfz-color-orange-200);
  color: var(--pfz-text-warning-default);
}

.tag--error svg {
  color: var(--pfz-icon-error);
}

.tag__text {
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-bold);
  line-height: var(--pfz-line-height-18);
}

.tag--disabled {
  border-color: var(--pfz-color-gray-10) !important;
  background: var(--pfz-background-surface-disabled) !important;
  color: var(--pfz-text-disabled) !important;
}

.tag--disabled svg {
  color: var(--pfz-text-disabled);
}
