/* src/components/atoms/NavMenuItem/NavMenuItem.module.css */

.nav-menu-item {
  display: flex;
  align-items: center;
  width: 100%;
  gap: var(--spacing-02);
  padding: var(--padding-size-xs);
  cursor: pointer;
  border-radius: var(--border-8);
  transition: background-color 0.3s ease;
  white-space: nowrap; /* Ensure text doesn't wrap */
}

.nav-menu-item:hover,
.nav-menu-item.active {
  background-color: #e2e8f0;
  color: var(--pfz-color-primary-800);
}

.nav-menu-item:hover path,
.nav-menu-item.active path {
  fill: var(--pfz-color-primary-800);
}

.nav-menu-item.active {
  border-radius: var(--border-8);
}

.text {
  overflow: hidden;
  white-space: nowrap;
  transition:
    transform 0.3s ease,
    opacity 0.3s ease;
  transform: scaleX(0);
  opacity: 0;
  font-size: var(--pfz-font-size-3);
  font-weight: var(--pfz-font-weight-500);
  line-height: var(--pfz-line-height-24);
}

.expanded .text {
  transform: scaleX(1);
  opacity: 1;
}

.betaTagText {
  text-transform: uppercase;
}

.betaTagText p {
  font-size: var(--pfz-font-size-1);
}

.leftNavTooltip {
  color: var(--pfz-color-gray-600) !important;
  white-space: nowrap;
  max-width: fit-content;
  text-align: center !important;
  font-size: var(--pfz-font-size-1) !important;
  font-style: normal;
  line-height: var(--pfz-line-height-18) !important;
  display: flex;
  height: 20px;
  bottom: 40px !important;
  left: 30px !important;
  padding: var(--spacing-01) var(--spacing-04) !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-02);
  border-radius: var(--border-radius-rounded) !important;
  background: var(--pfz-background-surface-base-secondary) !important;
  box-shadow:
    0px 4px 8px -2px rgba(2, 0, 94, 0.1),
    0px 2px 4px -2px rgba(2, 0, 94, 0.06) !important;
  -webkit-border-radius: var(--border-radius-rounded) !important;
  -moz-border-radius: var(--border-radius-rounded) !important;
  -ms-border-radius: var(--border-radius-rounded) !important;
  -o-border-radius: var(--border-radius-rounded) !important;
}

.betaTooltipLarge {
  width: var(--pfz-content-header-tooltip-width) !important;
  padding: var(--padding-size-xs) var(--padding-size-l) !important;
  text-align: left !important;
  margin-left: var(--spacing-09);
  text-wrap: wrap;
  margin-top: var(--margin-size-xs);
  color: var(--pfz-text-default-color);
}
