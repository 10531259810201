.navbar {
  display: flex;
  flex-direction: column;
  width: 260px;
  height: 100%;
  background-color: var(--pfz-color-ghost-white);
  border-right: 1px solid #d4d4d8;
  transition: width 0.5s ease;
}

.navbar.collapsed {
  width: 80px;
}

.brand-nav-section {
  padding: 16px;
}

.brand-nav-section .brand-logo {
  display: flex;
}

.brand-nav-section .create-new {
  margin-top: 40px;
  padding: 12px 28px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  transition:
    width 0.3s ease,
    padding 0.3s ease;
  white-space: nowrap;
  background-color: transparent;
  width: auto;
}

.brand-nav-section.collapsed {
  display: flex;
  flex-direction: column;
}

.brand-nav-section .create-new .text {
  display: inline-block;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Clip overflowing text */
  transition: opacity 0.3s ease;
}

.brand-nav-section .create-new.collapsed {
  padding: 12px;
  width: 48px;
  height: 48px;
}

.brand-nav-section.collapsed .create-new {
  width: auto;
}

.createNewBtnTooltip {
  color: var(--pfz-color-gray-600) !important;
  white-space: nowrap;
  max-width: fit-content;
  text-align: center !important;
  font-size: var(--pfz-font-size-1) !important;
  font-style: normal;
  line-height: var(--pfz-line-height-18) !important;
  display: flex;
  height: 20px;
  bottom: 40px !important;
  left: 30px !important;
  padding: var(--spacing-01) var(--spacing-04) !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-02);
  border-radius: var(--border-radius-rounded) !important;
  background: var(--pfz-background-surface-base-secondary) !important;
  box-shadow:
    0px 4px 8px -2px rgba(2, 0, 94, 0.1),
    0px 2px 4px -2px rgba(2, 0, 94, 0.06) !important;
  -webkit-border-radius: var(--border-radius-rounded) !important;
  -moz-border-radius: var(--border-radius-rounded) !important;
  -ms-border-radius: var(--border-radius-rounded) !important;
  -o-border-radius: var(--border-radius-rounded) !important;
}

.admin-menu,
.footer-menu {
  border-top: 1px solid #d4d4d8;
}

.footer-menu {
  height: 144px;
}

.other-menu {
  margin-top: auto;
}

.green-circle-icon {
  position: absolute;
  left: 26px;
  top: 8px;
  width: 14px;
  height: 14px;
}

.expanded-green-circle-icon {
  position: relative;
  left: -18px;
  top: -9px;
  width: 14px;
  height: 14px;
}
